import { BaseResponseInterface } from '@interface/BaseResponseInterface';
import { ChangeLanguageInterface, PlatformType } from '@interface/UserInterface';
import { localStorageService } from '@utils/localStorage';
import { AxiosError, AxiosResponse } from 'axios';

import { axiosAuthInstance } from './axios.authConfig';
import { axiosWorkforceAuthInstance } from './axios.workforceAuthServiceConfig';

export const postChangeLanguage = async (
  body: ChangeLanguageInterface
): Promise<BaseResponseInterface> => {
  const isGC = ((await localStorageService.getPlatform('platform')) as PlatformType) === 'GC';
  const axiosAuth = isGC ? axiosAuthInstance : axiosWorkforceAuthInstance;

  return axiosAuth
    .post(`/users/language?lang=${body.lang}`)
    .then((res: AxiosResponse<BaseResponseInterface>) => res.data)
    .catch((error: AxiosError) => {
      throw new Error(error?.message);
    });
};
