import { localStorageService } from '@utils/localStorage';
import { AxiosResponse } from 'axios';
import { PlatformType, ResponseMeInterface } from 'interface/UserInterface';

import { axiosAuthInstance } from './axios.authConfig';
import { axiosWorkforceAuthInstance } from './axios.workforceAuthServiceConfig';

export const getUserLogout = async (): Promise<ResponseMeInterface> => {
  const isGC = ((await localStorageService.getPlatform('platform')) as PlatformType) === 'GC';
  const axiosAuth = isGC ? axiosAuthInstance : axiosWorkforceAuthInstance;

  return axiosAuth
    .get('/logout')
    .then((res: AxiosResponse<ResponseMeInterface>) => res.data)
    .catch((err) => err);
};
