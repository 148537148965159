import { postChangeLanguage } from '@api/POST_ChangeLanguage';
import { useAuth } from '@context/AuthContext';
import { useI18n } from '@hooks/useI18n';
import { useNavigationConfig } from '@hooks/useNavigationConfig';
import { DataMeInterface, LanguageType } from '@interface/UserInterface';
import {
  ColorBlue,
  ColorLight,
  Navbar,
  NavbarCompanyDropdown,
  NavbarUserDropdown,
  Sidebar,
  useClickOutside
} from '@uangcermat/uikit-web';
import { getGuardedNavigation } from '@utils/getGuardedNavigation';
import { localStorageService } from '@utils/localStorage';
import { syncRefreshToken } from '@utils/shouldRefreshToken';
import { useRouter } from 'next/router';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import pkgJson from '../../package.json';

// FIXME: styled should be fixed at uiKit
const NavbarContainerStyled = styled.div`
  ul {
    z-index: 9;
    top: 4rem !important;
  }
  img {
    object-fit: scale-down;
  }
  //FIXME: styled should be fixed at uiKit for rounded style
  img[data-test-id='avatarComponent'] {
    border-radius: 4px !important;
    object-fit: fill;
    min-width: 40px !important;
  }
  //FIXME: styled should be fixed at uiKit when apps only set to one company
  > div {
    div[data-test-id='navbarCompany'] {
      margin-left: 54px;
    }
    > div:nth-child(2) > div:first-child {
      display: none;
    }
  }
`;

const companyImgUrl = '/images/navbar-logo.svg';

const appData = {
  appName: process.env.APP_NAME,
  appVersion: pkgJson.version
};

const LayoutStyled = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100vh;
`;

const MainStyled = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
  overflow: hidden;
`;

const SidebarContainerStyled = styled.div`
  min-width: 208px !important;
  // TODO: move this styling to UI kit
  li > div > p {
    font-size: 14px;
    line-height: 16px;
  }
`;

const ContentContainerStyled = styled.div`
  display: flex;
  flex: 1;
  overflow-x: auto;
  overflow-y: hidden;
`;

const initialUserData: NavbarUserDropdown = {
  name: '',
  email: '',
  avatarUrl: '',
  isShowAccount: false,
  isHoverable: true,
  onClickProfile: () => undefined,
  onClickLogout: () => undefined
};

const initialCompanyDropdown: NavbarCompanyDropdown = {
  onClickBranch: () => undefined,
  companyName: '',
  companyBranchRefElm: null,
  logoUrl: '',
  listCompany: [],
  isShowBranch: false,
  activeColor: ColorBlue.bleuDeFrance,
  hoverColor: '',
  hoverItemColor: ColorLight.whiteSmoke,
  onClickBranchSwitcher: () => undefined
};

export default function DashboardLayout({ children }: { children: React.ReactNode }) {
  const { logout, userAuth, setUserAuth } = useAuth();
  const [initSetup, setInitSetup] = useState(true);
  const [activeId, setActiveId] = useState('');
  const [companyData, setCompanyData] = useState<NavbarCompanyDropdown>(initialCompanyDropdown);
  const { changeLanguage } = useI18n();
  const currentLanguage = (localStorageService.getLanguage('i18nextLng') as LanguageType) ?? 'en';

  const [isOpen, setIsOpen] = useState(false);
  const wrapperRef = useRef(null);
  useClickOutside({
    ref: wrapperRef,
    cb: () => {
      setIsOpen(false);
    }
  });

  const handleChangeLanguage = async (to: LanguageType) => {
    await postChangeLanguage({ lang: to });
    await syncRefreshToken();
    changeLanguage(to);
    window.location.reload();
  };
  const [userData, setUserData] = useState<NavbarUserDropdown>({
    ...initialUserData,
    isHoverable: true,
    onClickLogout: logout
  });

  useEffect(() => {
    changeLanguage(currentLanguage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { navigationConfig } = useNavigationConfig();

  const router = useRouter();

  const changeActiveCompany = useCallback(
    (companyName: string) => {
      const company = userAuth?.companies.find((company) => company.name === companyName);
      if (company) {
        setCompanyData({
          ...companyData,
          companyName: company.name,
          companyBranchRefElm: null,
          logoUrl: company.logo
        });
      }
    },
    [companyData, userAuth?.companies]
  );

  const setNavbarData = useCallback(
    (data: DataMeInterface) => {
      setUserData({
        ...userData,
        hoverColor: ColorBlue.bleuDeFrance,
        hoverItemColor: ColorLight.whiteSmoke,
        email: data.email || '',
        name: data.name || '',
        avatarUrl: data?.profilePicture || '',
        onClickLogout: logout
      });
      changeActiveCompany(data.companies[0]?.name);
      setInitSetup(false);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [changeActiveCompany, logout, setUserAuth, userAuth, userData.isShowAccount]
  );

  useEffect(() => {
    if (userAuth && initSetup) {
      setNavbarData(userAuth);
    }
  }, [initSetup, setNavbarData, userAuth]);

  type SidebarMapType = {
    [key: string]: string;
  };

  useEffect(() => {
    const sidebarActiveIdMapper: SidebarMapType = {
      '/dashboard': 'activityReport.view',
      '/dashboard/loan/historyList': 'incoming.history',
      '/dashboard/active': 'active.overview',
      '/dashboard/active/loanList': 'active.index',
      '/dashboard/active/history': 'active.history',
      '/dashboard/accounts/users': 'users.view'
    };

    setActiveId(sidebarActiveIdMapper[router.route]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setActiveId]);

  return (
    <>
      <LayoutStyled>
        <NavbarContainerStyled>
          {/* FIXME: navbar company logo style */}
          <Navbar
            appLogoUrl={companyImgUrl}
            onClickLogo={() => router.push('/dashboard')}
            company={companyData}
            user={{
              ...userData,
              userRefElm: wrapperRef,
              isShowAccount: isOpen,
              onClickProfile: () => {
                setIsOpen((prev) => !prev);
              }
            }}
            languageSwitcher={{
              activeLanguage: currentLanguage,
              isOutsideLanguageSwitcher: true,
              hoverColor: ColorBlue.bleuDeFrance,
              onClickEnglish: async () => {
                if (userAuth) {
                  setUserAuth({
                    ...userAuth,
                    lang: 'en'
                  });
                }
                await handleChangeLanguage('en');
              },
              onClickBahasa: async () => {
                if (userAuth) {
                  setUserAuth({
                    ...userAuth,
                    lang: 'id'
                  });
                }
                await handleChangeLanguage('id');
              }
            }}
            bgColor={ColorBlue.darkBlue}
          />
        </NavbarContainerStyled>
        <MainStyled>
          <SidebarContainerStyled>
            <Sidebar
              dataTestId="sidebar"
              navigationConfig={getGuardedNavigation({
                navConfig: navigationConfig,
                userPermission: userAuth?.permissions ?? []
              })}
              onClickNav={(nav) => {
                setActiveId(nav.id);
                if (nav.navLink) {
                  router.push(nav.navLink);
                }
              }}
              activeId={activeId}
              appName={appData.appName}
              appVersion={appData.appVersion}
            />
          </SidebarContainerStyled>
          <ContentContainerStyled>{children}</ContentContainerStyled>
        </MainStyled>
      </LayoutStyled>
    </>
  );
}
