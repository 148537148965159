import '@uangcermat/uikit-web/build/index.css';
import 'tailwindcss/tailwind.css';
import '../styles/font.css';

import AppContext from '@context/AppContext';
import { useI18n } from '@hooks/useI18n';
import DashboardLayout from '@layout/DashboardLayout';
import { ColorLight, gajicermatColorScheme } from '@uangcermat/uikit-web';
import { appCookies } from '@utils/appCookies';
import { AppProps } from 'next/app';
import Head from 'next/head';
import { useRouter } from 'next/router';
import React, { useEffect } from 'react';
import { Hydrate, QueryCache, QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { track } from 'react-tracking';
import { createGlobalStyle, ThemeProvider } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  html,
  body {
    background-color: ${ColorLight.whiteSmoke};
    height: 100%;
    margin:0;
    padding: 0;
    overflow: hidden;
  }

  * {
    box-sizing: border-box;
  }
  
  a {
    color: inherit;
    text-decoration: none;
  }
  #__next {
    height: 100%;
  }
  .wrapper {
    min-height: 100vh;
    max-width: 500px;
    margin: auto;
    background-color: ${ColorLight.whiteSmoke};
  }

  ::-webkit-scrollbar {
    height: 8px;
    width: 8px;
  }

  ::-webkit-scrollbar-track {
    position: absolute;
    background: #FFFFFF;
    box-shadow: inset 0px 0px 17px rgba(0, 0, 0, 0.25);
  }

  //FIXME: add props maxHeight on checkpicker toolkit
  .rs-picker-check-menu {
    max-height: 288px !important;
  }
  
  ::-webkit-scrollbar-thumb {
    position: absolute;
    background: #52616B; 
    border-radius: 23px;
  }

  [data-test-id='navbarCompany'] {
    display: none;
  }

  [data-test-id='sidebar'] > ul > li:first-child > div:nth-child(2) {
    padding-left: 24px !important;
  }
  [data-test-id='sidebar'] > ul > li > p {
    font-size: 14px;
    line-height: 16px;
  }
  [data-test-id='sidebar'] {
    width: 250px;
  }
`;

const { getCookie } = appCookies();

const queryCache = new QueryCache({
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onSuccess: async (success: any, query) => {
    const token = await getCookie({
      name: 'access_token'
    });
    if (token && typeof success === 'undefined') {
      query.fetch();
    }
  }
});

// FIXME: add props types of MyApp, got an issue from nextjs about parse `err` props
// eslint-disable-next-line react/prop-types
const MyApp = ({ Component, pageProps, err }: AppProps & { err: unknown }) => {
  const [queryClient] = React.useState(
    () =>
      new QueryClient({
        queryCache,
        defaultOptions: {
          queries: {
            retry: 1,
            retryDelay: 500,
            retryOnMount: false,
            refetchOnWindowFocus: false
          },
          mutations: {
            retry: 1
          }
        }
      })
  );

  const router = useRouter();

  type RouteMapperType = {
    [key: string]: string;
  };

  const { init } = useI18n();

  useEffect(() => {
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const routerHeadMapper: RouteMapperType = {
    '/activities': 'Activity Report'
  };

  const headTitle = `${
    routerHeadMapper[router.pathname] ? routerHeadMapper[router.pathname] + ' - ' : ''
  }${process.env.APP_NAME}`;

  const ComponentWrapper = () => {
    return <Component {...pageProps} err={err} />;
  };

  return (
    <>
      <Head>
        <meta charSet="utf-8" />
        <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
        <meta
          name="viewport"
          content="width=device-width,initial-scale=1,minimum-scale=1,maximum-scale=1,user-scalable=no"
        />

        <title>{headTitle}</title>
        <meta name="description" content={headTitle} />
        <meta name="keywords" content={headTitle} />

        <meta name="msapplication-TileColor" content="#ffffff" />
        <meta name="theme-color" content="#ffffff" />
      </Head>
      <GlobalStyle />
      <div id="root" className="h-full">
        <QueryClientProvider client={queryClient}>
          {process.env.NODE_ENV === 'development' && <ReactQueryDevtools initialIsOpen={false} />}
          <Hydrate state={pageProps.dehydratedState}>
            <ThemeProvider theme={gajicermatColorScheme}>
              {/* //FIXME: [CRITICAL] handle authorization / registration page base on user login */}
              <AppContext>
                {[
                  'dashboard',
                  'user',
                  'company',
                  'role',
                  'permission',
                  '[id]',
                  'new',
                  'omgroup'
                ].includes(router.pathname.split('/').pop() || '') ? (
                  <DashboardLayout>
                    <ComponentWrapper />
                  </DashboardLayout>
                ) : (
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  //@ts-ignore
                  <Component {...pageProps} err={err} />
                )}
              </AppContext>
            </ThemeProvider>
          </Hydrate>
        </QueryClientProvider>
      </div>
    </>
  );
};

const TrackedApp = track(
  {},
  {
    dispatch: (data: AppTrackingInterface) => {
      const trackProperties = {};

      if (data.properties) {
        Object.assign(trackProperties, data.properties);
      }

      // TODO: send tracker data to server
    }
  }
)(MyApp);

export default TrackedApp;
