import { localStorageService } from '@utils/localStorage';
import {
  PlatformType,
  PostBodyRefreshTokenInterface,
  ResponseLoginInterface
} from 'interface/UserInterface';

export const GetRefreshToken = async ({
  token
}: PostBodyRefreshTokenInterface): Promise<ResponseLoginInterface> => {
  const isGC = ((await localStorageService.getPlatform('platform')) as PlatformType) === 'GC';

  return new Promise((resolve, reject) => {
    fetch(
      `${
        isGC ? process.env.AUTH_SERVICE_URL : process.env.WORKFORCE_AUTH_SERVICE_URL
      }/refresh_token`,
      {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
          'x-platform': process.env.APP_PLATFORM
        }
      }
    )
      .then((res) => res.json())
      .then((res: ResponseLoginInterface) => {
        return resolve(res);
      })
      .catch((err) => {
        return reject(err);
      });
  });
};
