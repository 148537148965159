import { NavigationMenuInterface } from '@uangcermat/uikit-web';
import { useTranslation } from 'react-i18next';

export const useNavigationConfig = () => {
  const { t } = useTranslation(['menu']);
  const navigationConfig: Array<NavigationMenuInterface> = [
    {
      id: 'dashboard',
      icon: 'notebook',
      title: t('menu:dashboard'),
      children: [],
      navLink: '/dashboard'
    },
    {
      id: 'company',
      icon: 'building',
      title: t('menu:company'),
      children: [
        {
          id: 'company.read',
          title: t('menu:companyList'),
          navLink: '/company'
        }
      ]
    },
    {
      id: 'omgroup',
      icon: 'building',
      title: t('menu:group'),
      children: [
        {
          id: 'omgroup.read',
          title: t('menu:groupList'),
          navLink: '/omgroup'
        }
      ]
    },
    {
      id: 'user',
      icon: 'usergroup',
      title: t('menu:menuUser'),
      children: [
        {
          id: 'user.read',
          title: t('menu:userList'),
          navLink: '/user'
        }
      ]
    },
    {
      id: 'role',
      icon: 'eyeon',
      title: t('menu:menuRole'),
      children: [
        {
          id: 'role.read',
          title: t('menu:roleList'),
          navLink: '/user/role'
        }
      ]
    },
    {
      id: 'permission',
      icon: 'informationcircle',
      title: t('menu:menuAccess'),
      children: [
        {
          id: 'permission.read',
          title: t('menu:permissionList'),
          navLink: '/user/permission'
        }
      ]
    }
  ];

  return { navigationConfig };
};
